import { Breadcrumb, DatePicker, Input, Modal, Table, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Events.scss';
import { BiRefresh } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import { BASE_URL, DEPLOYED_URL, LOGGEDIN_USER } from '../../../CONSTANTS';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { dateFormat } from '../../../utils/genericFuntions';

const Events = () => {
  const { RangePicker } = DatePicker;
  const [events, setEvents] = useState([]);
  const eventColumns = [
    {
      title: "Event Name",
      dataIndex: "event"
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      filters: Array.from(
        new Set(events.map((el) => el.contractName))
      ).map((contractName) => ({
        text: contractName,
        value: contractName,
      })),
      onFilter: (value, record) => record.contractName === value,
    },
    {
      title: "Created",
      dataIndex: "createdOn",
      render: (text) => dateFormat(text),
      sorter: (a, b) => {
        const dateA = moment(a.createdOn);
        const dateB = moment(b.createdOn);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    }
  ]

  const [filter, setFilter] = useState({
    search: "",
    startDate: {
      dateObj: "",
      date: ""
    },
    endDate: {
      dateObj: "",
      date: ""
    }
  })

  const bredCrumbItems = [
    {
      title: "API & Services"
    },
    {
      title: "EVENT API > Contract Events",
      href: `${DEPLOYED_URL}/allEvents`
    }
  ]
  const [loading, setLoading] = useState(false)

  const getAllEvents = async() => {
    const userDetails = await Auth.currentSession();
    setLoading(true)
    axios.post(`${BASE_URL}/api/contract/getAllEvents`, {
      search: filter.search,
      startDate: filter.startDate.date,
      endDate: filter.endDate.date,
      userId:LOGGEDIN_USER.userId
    }, {
      headers: {
        cognitotoken: userDetails.accessToken.jwtToken
      }
    })
      .then(res => {
        setLoading(false)
        // let temp = res.data.data.map(el => {
        //   return {
        //     ...el,
        //     ...el.returnValues,
        //     event: el.event.replace('_', ''),
        //   }
        // })

        // temp.forEach(element => {
        //   delete element.returnValues
        //   delete element[0]
        //   delete element[1]
        //   delete element[2]
        //   delete element['removed']
        //   delete element['isActive']
        //   delete element['__v']

        // });
        setEvents(res.data.data);
        

      }).catch(err => {
        setLoading(false)
        message.error(err.response.data.message)
      })
  }
  useEffect(() => {
    if(LOGGEDIN_USER.userId){
      getAllEvents();
    }
  }, [filter,LOGGEDIN_USER.userId])

  const [moreDetailsModal, setMoreDetailsModal] = useState({
    show: false,
    data: []
  });

  return (
    <div className='p-3 pt-1'>
    <div className='py-2 bg-white px-2 text-dark'>
      <Breadcrumb separator=">" items={bredCrumbItems} />
      <div className='mt-2'>
        <p className='h5 p-0 m-0 text-dark mb-2 text-uppercase'>Contract Events</p>
        <p className='text-secondary'>You can search History of Transactions.</p>
      </div>
    </div>
    <div className='bg-white d-flex justify-content-between w-100 mb-2 pt-3 mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex w-100 ms-2'>
            <Input value={filter.search} className='w-50' onChange={e => setFilter(prev => ({ ...prev, search: e.target.value }))} prefix={<AiOutlineSearch />} placeholder='Search' />
            <RangePicker allowClear={false} value={[filter.startDate.dateObj, filter.endDate.dateObj]} className='date-picker ms-2' onChange={(e, m) => { setFilter(prev => ({ ...prev, startDate: { date: m[0], dateObj: e[0] }, endDate: { date: m[1], dateObj: e[1] } })) }} />
            <BiRefresh style={{ fontSize: '35px' }} onClick={e => setFilter(prev => ({ search: "", startDate: { dateObj: "", date: "" }, endDate: { dateObj: "", date: "" } }))} className='ms-3 cp' />
          </div></div>
      </div>
      <Table className='mt-3' onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            setMoreDetailsModal({
              show: true,
              data: record
            })
          }
        }
      }} showHeader={events.length ? true : false} loading={loading} columns={eventColumns} dataSource={events} pagination={{ pageSize: 6 }} />
      {<Modal open={moreDetailsModal.show} className='w-75' footer='' onCancel={() => setMoreDetailsModal({ show: false, data: [] })}>
        <table className='table'>
          <tbody>
            {Object.keys(moreDetailsModal.data).map(el => {
              return <tr>
                <td className='fw-bold'>{el}</td>
                <td style={{wordBreak:'break-word'}}>{JSON.stringify(moreDetailsModal.data[el]).replace(/"/g, '')}</td>
              </tr>
            })}
          </tbody>
        </table>
      </Modal>}
    </div>
  )
}

export default Events;